<template>
  <div class="machine-wrapper" :class="stella">
    <div class="inner">
      <router-link :to="{ name: 'Machine Details', params: { id: id } }">
        <div class="machine">
          <img :src="src" class="machine__image" />
          <h3 class="machine__title">{{ title }}</h3>
        </div>
        <div class="clearfix"></div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MachineCard',
  props: ['title', 'src', 'id', 'stella'],
};
</script>

<style scoped>
.machine-wrapper {
  margin-bottom: 10px;
  width: 33%;
  float: left;
  padding: 0;
}

.inner {
  padding: 10px;
}
.machine__image {
  max-width: 100%;
}

.machine__title {
  z-index: 9;
  padding: 0;
  color: var(--var-secondary-color) !important;
  font-weight: 600;
  font-size: 80%;
}

a {
  text-decoration: none;
}

h3 {
  padding: 0;
  margin: 0;
}

.machine__image {
  width: 100% !important;
  height: auto;
}
</style>
